<template>
    <div class="material-test-record-list-page">
      <a-card title="物料检测记录">
        <!-- 搜索区域 -->
        <a-row gutter="16">
          <a-col :span="24" style="max-width: 200px; margin-bottom: 12px">
            <a-input
              v-model="searchForm.keyword"
              placeholder="采购单号 / 物料名称"
              allowClear
              @pressEnter="search"
            />
          </a-col>
          <a-col :span="24" style="width: 100px; margin-bottom: 12px">
            <a-button type="primary" icon="search" @click="search">查询</a-button>
          </a-col>
        </a-row>
    
        <!-- 表格展示待检物料记录 -->
        <a-table
          :columns="columns"
          :dataSource="tableData"
          :rowKey="record => record.id"
          :loading="loading"
          bordered
        >
          <!-- 操作列插槽：旧版语法 -->
          <div slot="action" slot-scope="value, item">
            <a-button type="primary" size="small" @click="handleCreate(item)">
              创建测试记录
            </a-button>
          </div>
        </a-table>
      </a-card>
    </div>
  </template>
  
  <script>
  import { pendingPurchaseOrderList } from '@/api/purchasing'
  
  export default {
    name: 'MaterialTestRecordList',
    data() {
      return {
        // 搜索条件：采购单号或物料名称
        searchForm: { keyword: "", page: 1, page_size: 16 },
        loading: false,
        tableData: [],
      }
    },
    computed: {
      columns() {
        return [
          {
            title: "序号",
            dataIndex: "index",
            key: "index",
            customRender: (value, record, index) => index + 1,
            width: 45,
          },
          { title: "采购单号", dataIndex: "purchase_order_number", key: "purchase_order_number" },
          { title: "物料名称", dataIndex: "material_name", key: "material_name" },
          { title: "数量", dataIndex: "purchase_quantity", key: "purchase_quantity" },
          { title: "检测标准", dataIndex: "standard_name", key: "standard_name" },
          {
            title: "操作",
            key: "action",
            scopedSlots: { customRender: "action" },
          },
        ];
      },
    },
    created() {
      this.fetchData();
    },
    methods: {
      async fetchData() {
        this.loading = true;
        try {
          const res = await pendingPurchaseOrderList(this.searchForm);
          // 假设 API 返回格式为 { results: [ {...}, ... ] }
          this.tableData = res || [];
        } finally {
          this.loading = false;
        }
      },
      search() {
        this.searchForm.page = 1;
        this.fetchData();
      },
      handleCreate(item) {
        // 点击“创建测试记录”后，跳转到独立的创建页面
        // 传递采购单和物料信息作为查询参数
        this.$router.push({
          name: 'MaterialTestRecordFormPage',  // 路由名称，请确保在路由配置中定义
          query: {
            purchase_order_id: item.purchase_order,
            purchase_order_no: item.purchase_order_number,
            material_id: item.materials,
            material_name: item.material_name,
            purchase_materials_id:item.id
          }
        });
      },
    },
  };
  </script>
  
  <style scoped>
  .material-test-record-list-page {
    padding: 16px;
  }
  </style>
  